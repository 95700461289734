import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuard } from "../../../guards/auth.guard";

import { Constants } from "../../../constants/constants";

// Components
import { AwsAccountsComponent } from "./aws-accounts.component";
import { AwsAccountListComponent } from "./aws-account-list/aws-account-list.component";
import { AwsAccountFormComponent } from "./aws-account-form/aws-account-form.component";

// Resolver
import { AwsAccountListResolverService } from "./aws-account-list/aws-account-list-resolver.service";

const awsAccountRoutes: Routes = [
    {
        path: Constants.urls.configuration.aws + "/new",
        component: AwsAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.aws + "/:id/:action",
        component: AwsAccountFormComponent,
        canActivate: [AuthGuard]
    },
    {
        path: Constants.urls.configuration.aws,
        component: AwsAccountsComponent,
        canActivateChild: [AuthGuard],
        data: {
            help: [
                {
                    menu: "HELP_MENU.AWS_ACCOUNTS",
                    link: "https://zixidocumentation.atlassian.net/wiki/x/MwAjFw"
                }
            ]
        },
        children: [
            {
                path: "",
                component: AwsAccountListComponent,
                resolve: {
                    awsAccount: AwsAccountListResolverService
                }
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(awsAccountRoutes)],
    exports: [RouterModule]
})
export class AwsAccountsRouting {}
