import { Component, OnInit, OnDestroy, QueryList, ViewChildren, ViewChild, ElementRef } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";
import { take } from "rxjs/operators";

import { NgbSortableHeader } from "../../../../directives/sortable.directive";

import { Constants } from "../../../../constants/constants";
import { ModalService } from "../../../../components/shared/modals/modal.service";
import { UsersService } from "../../../account-management/users/users.service";
import { AwsAccount, KeyMap } from "../../../../models/shared";

import { AwsAccountsService } from "../aws-accounts.service";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { TranslateService } from "@ngx-translate/core";
import { TitleService } from "../../../../services/title.service";
import { Router } from "@angular/router";

import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { ZxEditTableRowButtonsComponent } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.component";
import { assignEditTableRowInputsFactory } from "src/app/components/shared/zx-edit-table-row-buttons/zx-edit-table-row-buttons.table-adapter";

@Component({
    selector: "app-aws-account-list",
    templateUrl: "./aws-account-list.component.html"
})
export class AwsAccountListComponent implements OnInit, OnDestroy {
    loading = true;
    refreshing = false;
    awsAccounts: AwsAccount[];

    sortedByText = "NONE";

    isAdmin: boolean;
    urls = Constants.urls;

    @ViewChildren(NgbSortableHeader) headers: QueryList<NgbSortableHeader>;
    @ViewChild("listPanel", { static: true }) listPanel: ElementRef;
    @ViewChild("listTop", { static: true }) listTop: ElementRef;
    @ViewChild("listBottom", { static: true }) listBottom: ElementRef;

    private awsAccountsSubscription: Subscription;

    private awsAccountsBS$ = new BehaviorSubject<AwsAccount[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            visible: true,
            width: 280,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AwsAccount>>(
                (row: KeyMap<AwsAccount>): string => row?.name,
                (row: KeyMap<AwsAccount>): string => row?.name,
                (): boolean => true
            ),
            sortBy: (row: KeyMap<AwsAccount>): string => row?.name,
            textValue: (row: KeyMap<AwsAccount>): string => row?.name
        },
        {
            header: this.translate.instant("ROLE"),
            columnDef: "role",
            visible: true,
            sticky: 2,
            width: 300,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<KeyMap<AwsAccount>>(
                (row: KeyMap<AwsAccount>): string => row?.iam_role_arn,
                (row: KeyMap<AwsAccount>): string => row?.iam_role_arn,
                (): boolean => true
            ),
            sortBy: (row: KeyMap<AwsAccount>): string => row?.iam_role_arn,
            textValue: (row: KeyMap<AwsAccount>): string => row?.iam_role_arn
        },
        {
            header: this.translate.instant("ACTIONS"),
            columnDef: "actions",
            visible: true,
            sticky: 3,
            align: "right",
            width: 60,
            component: ZxEditTableRowButtonsComponent,
            assignComponentsInputs: assignEditTableRowInputsFactory<AwsAccount, Promise<void>>({
                canEditCallBack: () => true,
                canDeleteCallBack: () => true,
                editRef: row => this.edit(row),
                deleteRef: row => this.delete(row)
            })
        }
    ];

    constructor(
        private router: Router,
        private ak: AwsAccountsService,
        private modalService: ModalService,
        private userService: UsersService,
        private mixpanelService: MixpanelService,
        private translate: TranslateService,
        private titleService: TitleService
    ) {
        // Set Title
        this.titleService.setTitle(this.translate.instant("AWS_ACCOUNTS"));
    }

    ngOnInit() {
        this.loading = true;

        // isAdmin
        this.userService.isAdmin.pipe(take(1)).subscribe(bool => {
            this.isAdmin = bool;
        });

        this.awsAccountsSubscription = this.ak.awsAccounts.subscribe(awsAccounts => {
            this.awsAccounts = awsAccounts;
            if (this.awsAccounts) {
                this.prepTableData();
                this.loading = false;
            }
        });
    }

    ngOnDestroy() {
        this.awsAccountsSubscription.unsubscribe();
    }

    async refresh() {
        this.refreshing = true;

        const awsAccounts = this.ak.refreshAwsAccounts().toPromise();
        await Promise.all([awsAccounts]);

        this.refreshing = false;
    }

    edit(awsAccount: AwsAccount): void {
        this.router.navigate([Constants.urls.configuration.aws, awsAccount.id, "edit"]);
    }

    async delete(awsAccount: AwsAccount) {
        await this.modalService.confirm(
            "DELETE",
            "AWS_ACCOUNT",
            async () => {
                const id = awsAccount.id;
                const result = await this.ak.deleteAwsAccount(awsAccount);
                if (result) {
                    this.mixpanelService.sendEvent("delete aws account", { id });
                } else return false;
            },
            awsAccount.name
        );
    }

    get awsAccounts$() {
        return this.awsAccountsBS$.asObservable();
    }

    private prepTableData() {
        if (this.awsAccounts) {
            this.awsAccountsBS$.next([...this.awsAccounts]);
        }
    }
}
