import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable, Subscriber } from "rxjs";

import { AwsAccountsService } from "../aws-accounts.service";
import { AwsAccount } from "../../../../models/shared";

@Injectable({
    providedIn: "root"
})
export class AwsAccountListResolverService implements Resolve<AwsAccount[]> {
    constructor(private tps: AwsAccountsService) {}

    resolve(): Observable<AwsAccount[]> | Observable<never> {
        return new Observable((observe: Subscriber<AwsAccount[]>) => {
            this.tps.refreshAwsAccounts().subscribe((awsAccounts: AwsAccount[]) => {
                observe.next(awsAccounts);
                observe.complete();
            });
        });
    }
}
