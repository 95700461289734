<!-- Title Bar -->
<div class="title-bar">
    <div class="title">
        <h1 title="{{ 'AWS_ACCOUNTS' | translate }}">{{ "AWS_ACCOUNTS" | translate }}</h1>
    </div>
</div>
<!-- Tabs -->
<div class="tab-container with-router">
    <div class="tabset">
        <ul ngbNav #nav="ngbNav" class="justify-content-start nav nav-tabs">
            <li ngbNavItem id="aws-accounts-list-tab">
                <a ngbNavLink class="nav-link" title="{{ 'ACCOUNTS' | translate }}">{{ "ACCOUNTS" | translate }}</a>
                <ng-template ngbNavContent>
                    <router-outlet></router-outlet>
                </ng-template>
            </li>
            <li ngbNavItem id="aws-accounts-doc-tab">
                <a ngbNavLink class="nav-link" title="{{ 'DOCUMENTATION' | translate }}">{{ "DOCUMENTATION" | translate }}</a>
                <ng-template ngbNavContent>
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col mt-3">
                                <h3 translate>AWS_DOCS.TITLE</h3>
                                <ol>
                                    <li>
                                        {{ "AWS_DOCS.ONE" | translate }}
                                        <a href="https://console.aws.amazon.com/iam" target="_blank">https://console.aws.amazon.com/iam</a>
                                    </li>
                                    <li translate>AWS_DOCS.TWO</li>
                                    <li>
                                        {{ "AWS_DOCS.THREE" | translate
                                        }}<button class="btn btn-sm btn-link" (click)="copyString(pre1String)" type="button" title="{{ 'COPY' | translate }}">
                                            <fa-icon icon="clipboard" size="md"></fa-icon>
                                        </button>
                                        <pre>{{ pre1String }}</pre>
                                    </li>
                                    <li translate>AWS_DOCS.FOUR</li>
                                    <li translate>AWS_DOCS.FIVE</li>
                                    <li translate>AWS_DOCS.SIX</li>
                                    <li translate>
                                        {{ "AWS_DOCS.SEVEN" | translate }}<br />
                                        {{ "AWS_DOCS.SEVEN_A" | translate }} <code>{{ userAccount?.aws_account_id }}</code
                                        ><br />
                                        {{ "AWS_DOCS.SEVEN_B" | translate }} <code>{{ userAccount?.aws_external_id }}</code>
                                    </li>
                                    <li translate>AWS_DOCS.EIGHT</li>
                                    <li translate>AWS_DOCS.NINE</li>
                                    <li translate>AWS_DOCS.TEN</li>
                                    <li>
                                        {{ "AWS_DOCS.ELEVEN" | translate
                                        }}<button class="btn btn-sm btn-link" (click)="copyString(pre2String)" type="button" title="{{ 'COPY' | translate }}">
                                            <fa-icon icon="clipboard" size="md"></fa-icon>
                                        </button>
                                        <pre>{{ pre2String }}</pre>
                                    </li>
                                    <li translate>AWS_DOCS.TWELVE</li>
                                    <li translate>AWS_DOCS.THIRTEEN</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </ng-template>
            </li>
        </ul>
        <div [ngbNavOutlet]="nav"></div>
    </div>
</div>
